.sponsors {
    display: inline-block;

    .sponsor {
        margin-right: 15px;
        display: inline-block;
        vertical-align: top;

        &:last-child {
            margin-right: 0;
        }
    }

    .sponsor-university-of-york {
        width: 120px;
        padding-top: .8rem;
    }

    .sponsor-paul-mellon-centre-for-studies-in-british-art {
        width: 140px;
        padding-top: 2rem;
    }

    .sponsor-the-british-library {
        img {
            height: 60px;
        }
    }
}