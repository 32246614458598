.pagination {

    justify-content: center;

    padding-top: 20px;
    padding-bottom: 25px;

    li {
        border-bottom: 0 !important;

        &.next,
        &.previous {
            display: none;
        }
    }

}