#home {
    position: relative;

    @include desktop {
        overflow-x: auto;
        padding-bottom: 0;
    }

    .title {
        line-height: normal;

        @include mobile {
            font-size: 1.5em;
        }

        // @include desktop {
        //     position: absolute;
        //     bottom: 0;
        //     left: 0;
        //     width: 100%;
        // }
    }

    p {
        margin-bottom: 1em;
    }

    .sponsors {
        @include desktop {
            position: sticky;
            bottom: 0;
            left: 0;
            width: 100%;
            margin: 0;
            padding: 0;
            background-color: white;
        }
    }

    #news {
        h3 {
            font-size: 1.5em;
            font-weight: bold;
            margin-bottom: 20px;
        }
    }

    .bottonPart {
        margin-top: 20px;
    }
}