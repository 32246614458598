#mapDisplay {
    .mapSliders {
        &:hover>.sliders {
            display: inline;
        }

        .mapSliderBtn {
            position: absolute;
            left: 15px;
            bottom: 15px;

            background-color: white;
            z-index: 495;

            border: 1px solid #ccc;
            border-radius: 4px;
            width: 40px;
            height: 40px;

            font-size: 15px;
            padding-top: 5px;

            box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
            cursor: pointer;
        }

        .sliders {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            background-color: white;
            padding: 10px;
            padding-bottom: 10px;
            padding-left: 20px;
            padding-right: 20px;
            z-index: 500;
            display: none;

            .recenterBtn {
                margin-top: 10px;
                margin-right: 10px;
            }

            .switchMapBtn {
                margin-top: 10px;
                margin-right: 10px;
            }

            .turnOffOverlaysBtn {
                margin-top: 10px;
            }
        }

        .map-title-container {
            width: 100%;

            .map-title {
                font-size: 12px;
                position: relative;

                span {
                    line-height: 0;
                    padding: 0;
                    margin: 0;
                    bottom: 0;
                    margin-left: 3px;
                    opacity: 0.3;
                }

                &:hover {
                    cursor: help;
                    font-weight: bold;

                    >.map-title-info {
                        display: inline;
                    }

                    >span {
                        opacity: 1;
                    }
                }
            }

            .map-title-info {
                display: none;
                position: absolute;
                left: 0;
                bottom: 25px;
                width: 100%;
                background-color: white;
                padding: 7px 10px;
                border: 1px solid #bbb;
                border-radius: 4px;
            }
        }
    }
}

.leaflet-control-attribution {
    display: none;
}