@charset "utf-8";

// Import a Google Font
@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');

// UglyQua
@font-face {
    font-family: 'UglyQua';
    src: url('../fonts/UglyQua/UglyQua-Italic.woff2') format('woff2'),
        url('../fonts/UglyQua/UglyQua-Italic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}

// Bulma
@import 'Bulma.scss';

// Custom styling

$headerHeight: 144px;
$headerHeightMobile: 100px;

$blue: '#3298dc';

$sliderTop: calc(100vh / 2); // used for the slider position

@import 'Helpers.scss';
@import 'Layout.scss';
@import 'Home.scss';
@import 'Header.scss';
@import 'Search.scss';
@import 'Searchresults.scss';
@import 'SwitchMapButton.scss';
@import 'Transitions.scss';
@import 'Details.scss';
@import 'Map.scss';
@import 'Split.scss';
@import 'AdvancedSearch.scss';
@import 'News.scss';
@import 'Pagination.scss';
@import 'ContactForm.scss';
@import 'Sponsors.scss';