$sponsorsHeight: 120px;


#search {

    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;

    .topPart {
        float: left;
        height: calc(100% - #{$sponsorsHeight});
        overflow-y: scroll;
        overflow-x: hidden;
        padding: 20px;

        .search-info-text {
            margin-top: 20px;
        }
    }

    .bottonPart {
        float: left;
        width: 100%;
        height: #{$sponsorsHeight};
        border-top: 1px solid #bbb;
        padding: 20px;
        text-align: center;
    }

    .field {

        margin-bottom: 1.5rem;

        .control:first-child {
            width: 100%;
        }

        .control {

            input {
                @include desktop {
                    font-size: 1.5rem;
                }
            }


            button {
                height: 100%;
            }
        }
    }

    .categories {

        .column {
            display: inline-block;

            button {
                font-size: 1rem;

                @include desktop {
                    font-size: 1.25rem;
                }
            }
        }

        .tag {
            margin-left: 10px;
            padding: 2px 5px;
            height: auto;
        }
    }

    .show-results-button {
        margin-top: 0.75rem;
        display: inline-block;
        width: 100%;
    }

}