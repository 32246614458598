html,
body {
  height: 100%;
  margin: 0;
  padding: 0;

  overflow-x: hidden;
  overflow-y: hidden;
}

#root,
.App {
  height: 100vh;
  width: 100vw;
}

.content-columns {
  position: relative;
  height: 100%;
  width: 100%;

  @include mobile {
    flex-direction: column;
  }

  @include desktop {
    flex-direction: row; // this is default
  }

  .map-column,
  .content-column,
  .search-column {
    height: 100%;

    @include mobile {
      overflow: scroll;
    }
  }

  .column-content {
    width: 100%;
    height: 100%;

    .content {
      padding: 20px;
      overflow-x: auto;

      height: calc(100vh - #{$headerHeightMobile});

      @include desktop {
        height: calc(100vh - #{$headerHeight});
      }

      p {
        margin-bottom: 20px;
      }
    }
  }
}

.h-100 {
  height: calc(100% - #{$headerHeightMobile});

  @include desktop {
    height: calc(100% - #{$headerHeight});
  }
}

// .columns {
// display: flex;
// flex-direction: row; // this is default
// align-items: stretch; // this will stretch the children vertically
// }

.search-column {
  background-color: #edeae2;
  // border-left: 1px solid rgba(#000000, .4);
  // box-shadow: 0 0 0.8em 0 black !important;

  .column-content {
    .content {
      padding: 0;
    }
  }
}

.padding-10 {
  padding: 10px;
}

.hidden {
  display: none;
}

.nowrap {
  white-space: nowrap;
}

.rotate-center {
  -webkit-animation: rotate-center 1s linear infinite both;
  animation: rotate-center 2s linear infinite both;
}

@keyframes rotate-center {
  0% {
    transform: rotate(0)
  }

  100% {
    transform: rotate(360deg)
  }
}