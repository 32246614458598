#details {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 900000;

  height: 100%;
  width: 100%;

  transform: translateY(110%);
  transition: all 0.5s ease-in-out;
  background: white;

  overflow-x: unset;

  h1 {
    font-size: 1.5rem;
  }

  @include desktop {
    overflow-x: unset;

    width: 50%;
    max-width: 1000px;

    transform: translateX(110%);

    resize: horizontal;

    h1 {
      max-width: 90%;
    }
  }

  .column-content {
    height: 100%;
    box-shadow: 0 0 0.8em black !important;

    .content {
      padding: 15px 20px;
      overflow-x: hidden;
      overflow-y: scroll;
      height: 100%;
      padding-bottom: 50px;
    }

    .details-table th {
      width: 60px;

      @include desktop {
        width: 150px;
      }
    }

    .subtitle {
      font-size: 1rem;
    }

    .how-to-cite {
      margin-top: 50px;
    }
  }

  .links {
    list-style: none;
    padding-left: 0;

    li:not(:first-child) {
      padding-top: 10px;
    }

    .details-link {
      text-decoration: none;

      &:hover {
        color: #165681;
      }
    }

    .full-text-icon {
      position: relative;

      .icon {
        padding-left: 10px;
      }

      .text-hover {
        display: none;
        position: absolute;
        left: 10px;
        top: -32px;
        background-color: white;
        border: 1px solid #bbb;
        border-radius: 2px;
        padding: 5px;
        width: 100px;
        text-align: center;
      }

      &:hover {
        .text-hover {
          display: inline;
        }
      }
    }
  }

  &.active {
    height: calc(100vh - #{$headerHeightMobile}) !important;
    transform: translateY(0%) translateY(#{$headerHeightMobile});

    @include desktop {
      height: 100% !important;
      transform: translateY(0);
      transform: translateX(0);
    }

    &.headerOpen {
      transition: all 1s ease-in-out;
      transform: translateY(100vh);

      @include desktop {
        transform: translateY(0);
      }
    }

    .closeIcon {
      opacity: 1;
    }
  }

  .closeIcon {
    position: absolute;
    right: 30px;
    top: 10px;

    font-size: 40px;
    line-height: 0;

    width: 39px;
    height: 39px;
    background-color: white;
    border-color: white !important;
    text-align: center;

    padding-left: 0;
    padding-top: 0;

    box-shadow: 0 0 0.8em black !important;

    &:hover {
      cursor: pointer;
      color: white;
      background-color: #{$blue};
    }
  }

  // .closeIcon {
  //   position: absolute;
  //   top: -40px;
  //   right: 0;

  //   opacity: 0;

  //   width: 40px;
  //   height: 40px;
  //   background-color: white;
  //   border-color: white !important;
  //   text-align: center;

  //   padding-left: 0;
  //   padding-top: 9px;

  //   box-shadow: 0 0 0.8em black !important;

  //   transition: opacity 0.5s ease-out;
  //   -moz-transition: opacity 0.5s ease-out;
  //   -webkit-transition: opacity 0.5s ease-out;
  //   -o-transition: opacity 0.5s ease-out;

  //   svg {
  //     transform: rotate(90deg);
  //   }

  //   &:hover {
  //     cursor: pointer;
  //     color: white;
  //     background-color: #{$blue};
  //   }

  //   &::after {
  //     z-index: 4;
  //     position: absolute;
  //     height: 0.8em;
  //     width: 4em;
  //     margin-left: -0.85em;
  //     margin-top: -2.5em;
  //     background-color: white;
  //     content: "";
  //     top: 80px;
  //     padding: 0;
  //     transition: transform 0.5s ease-in-out;
  //     display: block;
  //   }

  //   @include desktop {
  //     top: calc(50vh - 20px);
  //     left: -40px;
  //     right: auto;

  //     padding-left: 3px;

  //     svg {
  //       transform: rotate(0deg);
  //     }

  //     &::after {
  //       height: 4em;
  //       width: 0.8em;
  //       left: 63px;
  //       top: auto;
  //     }
  //   }
  // }
}

.no-styling {
  border: 0;
  background: none;
  text-align: left;
  padding: 0;
  text-decoration: underline;
  color: #{$blue};
  cursor: pointer;
}

.forceNoTransition {
  transition: all 0s ease-in-out !important;
}