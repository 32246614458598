$sizes: (0, 5, 10, 15, 20);
$positions: ('t': 'top',
  'l': 'left',
  'b': 'bottom',
  'r': 'right'
);

@each $size in $sizes {
  .m-#{$size} {
    margin: $size + px !important;
  }

  .p-#{$size} {
    padding: $size + px !important;
  }

  @each $position,
  $position-full in $positions {
    .m-#{$position}-#{$size} {
      margin-#{$position-full}: $size + px !important;
    }

    .p-#{$position}-#{$size} {
      padding-#{$position-full}: $size + px !important;
    }
  }
}