#header {
    // height: #{$headerHeightMobile};
    height: auto;
    width: 100%;
    position: relative;

    // @include desktop {
    // height: #{$headerHeight};
    // }

    .bg {
        background-repeat: repeat-x;
        background-size: cover;
        
        @include desktop {
            background-size: contain;
        }
    }

    .headerLink {
        font-family: 'UglyQua';
        font-weight: 500;
        font-style: normal;
        font-size: 28px;
        color: white;

        display: block;
        line-height: 30px;
        width: 100%;
        max-width: 280px;
        padding: 20px;


        @include desktop {
            font-size: 28px;
            color: white;
            text-align: right;

            padding: 20px;
            max-width: 370px;
            display: block;
        }
    }

    .menuBtn {
        font-size: calc(.9em + .1vw);
        color: white;
        white-space: nowrap;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, .25);

        float: right;
        cursor: pointer;

        border: 3px solid #fff;
        border-radius: 8px;
        padding: calc(.4em + .2vw) .5em calc(.4em + .2vw) .7em;
        background-color: rgba(255, 255, 255, .3);

        position: absolute;
        top: 1em;
        right: 1em;
        width: 5.5em;

        svg {
            vertical-align: middle;
        }
    }

    // .navbar-burger {
    //     border: 0;
    //     background-color: transparent;

    //     &:focus {
    //         outline: 0;
    //     }

    // }

    nav {
        background-color: #404040;

        max-height: 0;
        overflow: hidden;

        -webkit-transition: all .8s ease-in-out;
        -moz-transition: all .8s ease-in-out;
        transition: all .8s ease-in-out;

        &.active {
            max-height: calc(100vh - #{$headerHeightMobile});
            overflow-y: scroll;
            // max-height: 500px;

            @include desktop {
                overflow-y: hidden;
            }
        }

        @include desktop {
            max-height: 500px;
        }

        .navbar-item {
            padding: 5px 10px;
            color: #f9f9f9;

            width: 50%;
            display: inline-block;

            border-top: 1px solid #5a5a5a;
            border-bottom: 1px solid #5a5a5a;

            border-right: 1px solid #5a5a5a;
            // border-left: 1px solid #5a5a5a;

            &:hover,
            &:active,
            &:focus,
            &:focus-within {
                background-color: #0c5a73;
                color: #e2e2e2;
            }

            @include desktop {
                display: inline-block;
                padding: 10px 20px;
                width: auto;
                border-top: 0;
                border-bottom: 0;
                border-right: 1px solid #5a5a5a;
                // border-left: 1px solid #5a5a5a;
            }
        }

        #search {
            background-color: #edeae2;
        }
    }

}

// .navbar {

//     height: auto;
//     min-height: auto;
//     background-color: #404040;

//     .navbar-item {
//         padding: 10px 20px;
//         color: #f9f9f9;

//         border-right: 1px solid #5a5a5a;
//         border-left: 1px solid #5a5a5a;
//     }

//     // @include desktop {
//     //     position: absolute;
//     //     bottom: 0;
//     //     left: 0;
//     //     width: 100%;
//     // }

//     // background-color: rgba(255, 255, 255, .8);
//     z-index: 900000;
// }