.slider {
    height: 38px;
    width: 38px;
    background-color: #aaa;

    position: absolute;
    z-index: 50000;
    left: calc(5px - (38px / 2));
    top: calc(#{$sliderTop} - #{$headerHeight});

    border: 3px solid white;
    border-radius: 1000px;
    -webkit-box-shadow: 0px 0px 12px rgba(51, 51, 51, 0.5);
    -moz-box-shadow: 0px 0px 12px rgba(51, 51, 51, 0.5);
    box-shadow: 0px 0px 12px rgba(51, 51, 51, 0.5);

    .left-arrow,
    .right-arrow {
        position: absolute;
        width: 0;
        height: 0;
        border: 6px inset transparent;
        top: 50%;
        margin-top: -6px;
    }

    .left-arrow {
        border-right: 6px solid white;
        left: 50%;
        margin-left: -17px;
    }

    .right-arrow {
        border-left: 6px solid white;
        right: 50%;
        margin-right: -17px;
    }
}

#details .gutter div,
.gutter {
    background-color: #eee;
    background-repeat: no-repeat;
    background-position: 50%;
}

#details .gutter.gutter-horizontal div,
.gutter.gutter-horizontal {
    cursor: col-resize;
    // background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==");
}

.gutter {
    position: relative;
}

.split {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;

    .mapDisplay {
        float: left;
        height: 100%;
        overflow: hidden;
        // min-width: 250px;
    }

    .split-content {
        float: left;
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
    }
}

.split,
.gutter.gutter-horizontal {
    float: left;
    height: 100%;
    // overflow: hidden;
}

#details {
    .details-slider {
        >div {
            width: 10px;
            float: left;
            height: 100%;
            cursor: col-resize;

            background-color: #eee;
            background-repeat: no-repeat;
            background-position: 50%;

            .slider {
                top: #{$sliderTop};
            }
        }
    }
}