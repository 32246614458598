.content-column {
    .sweet-loading {
        position: absolute;
        z-index: 5000;
        background-color: rgba(255, 255, 255, 0.9);
        height: 100%;
        width: 100%;

        display: flex;
        align-items: center;
        justify-content: center;

        text-align: center;
        color: #3298dc;
        font-size: 10px;
    }

    .results {
        // overflow-y: scroll;
        height: 100%;
        padding-bottom: 10px;
        position: relative;

        .column {
            padding: 0.5rem;
            margin-bottom: 0;
            padding-bottom: 0;
        }

        .result {
            color: black;
            padding: 6px 10px;
            width: 100%;
            display: block;

            white-space: normal;
            height: auto;
            margin: 0;

            text-align: left;

            .highlightText {
                background-color: white;
                padding: 10px;
                border-radius: 4px;
                margin-top: 10px;
                color: black;

                &:hover {
                    color: black;
                }
            }

            .highlight {
                font-weight: bold;
            }

            &.is-info,
            &:hover {
                color: #fff;
                background-color: #3298dc;
            }

            &:hover .text2display {
                color: black;
            }

            .tag {
                margin-right: 10px;
                margin-bottom: 5px;
            }

            .full-text-source {
                margin-left: 5px;
            }
        }
    }

    .nothing-found {
        img {
            margin-top: 15px;
            width: 150px;
        }
    }
}