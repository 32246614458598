#advancedsearch {

    margin-bottom: 0.75rem;
    
    &.hidden {
        display: none;
    }
    
    .column-gap-1 {
        padding-top: 5px;
        padding-bottom: 0;
    }

    .inner {
        border: 1px solid #d4d4d4;
        border-radius: 4px;
        padding: 10px;

        font-size: .8em;

        .subtitle {
            font-size: 1.2em;
            font-weight: bold;
            margin-bottom: 5px;
        }

        .columns {
            margin-bottom: 5px;
        }

    }

}