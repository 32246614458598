#news {
    position: relative;
    padding-bottom: 20px;

    @include desktop {
        overflow-x: auto;
    }

    p {
        margin-bottom: 1em;
    }

    ul {
        li {
            h4 {
                font-weight: bold;
            }

            h5 {
                font-size: 11px;
                padding-top: 5px;
                padding-bottom: 5px;
            }

            a {
                display: inline;
            }

            border-bottom: 1px solid #bbb;
            padding-bottom: 15px;
            margin-bottom: 15px;
        }
    }
}

#newsArticle {
    position: relative;

    @include desktop {
        overflow-x: auto;
        padding-bottom: 0;
    }

    p {
        margin-bottom: 1em;
    }

    .articleContent {
        ul {
            list-style: circle;
            padding-left: 20px;
            padding-bottom: 15px;
        }
    }
}